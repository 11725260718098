/*
 * --------------------------------------------------------------------------- *
 * File: tagged-html.tsx                                                       *
 * Project: content-render                                                     *
 * Created Date: 23 Mar 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Thu Mar 23 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import useKatex from '../../useKatex';

export function TaggedHTML({ tag, html }: { tag: string; html: string }) {
  useKatex();
  return (
    <div className="container">
      <div className="row p-2">
        <div className="col-12 p-2 rounded border overflow-scroll">
          <h3>{tag}</h3>
          <hr />
          <div className="" dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </div>
    </div>
  );
}
