/*
 * --------------------------------------------------------------------------- *
 * File: cloud.ts                                                              *
 * Project: content-render                                                     *
 * Created Date: 14 Mar 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Fri Mar 17 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import axios from 'axios';
import { nanoid } from 'nanoid';
import sha1 from 'sha1';
import { Device } from './device';

export class Cloud {
  static BASE_DOMAIN: string = 'app.vyasedification.com';
  static CONTENT_SUB_DOMAIN: string = '5c08f948-4fa3-4708-b439-6cb85096301d';
  static TOKEN: string;

  static header = () => {
    const ts = Math.floor(Date.now() / 1000 / 86400);
    const n = sha1(nanoid());
    return {
      headers: {
        Accept: 'application/json',
        'X-Api-Key': n,
        Agent: sha1(`${n}${ts}`),
        Authorization: Cloud.TOKEN,
      },
    };
  };

  static loadQuantum = async (quantum: string): Promise<string[]> => {
    // @ts-ignore
    Device.contentUpdate(quantum, 'loading');
    const request = { action: 'getcontentquantumnodes', data: { quantum } };
    const url = `https://api.${Cloud.BASE_DOMAIN}/student`;
    let response = await axios.post(url, request, Cloud.header());
    if (response.status === 403) {
      // Perhaps you are on the edge of the time window. Make another attempt.
      response = await axios.post(url, request, Cloud.header());
    }

    if (response.status === 200 && response.data.success) {
      Device.contentUpdate(quantum, 'loaded');
      return response.data.value;
    }
    return [];
  };

  static loadQuestion = async (questionId: string): Promise<any> => {
    const url = `https://${Cloud.CONTENT_SUB_DOMAIN}.${Cloud.BASE_DOMAIN}/question/${questionId}.json`;
    console.log(url);
    return Cloud.fetchContent(url);
  };

  static fetchContent = async (url: string): Promise<any> => {
    const response = await axios.get(url, Cloud.header());
    if (response.status === 200) {
      return response.data;
    }
    return {};
  };
}

export interface CloudRequest {
  action: string;
  data: unknown;
}

export interface CloudResponse {
  success: boolean;
  value: any;
}
