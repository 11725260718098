import ARQuestion from './assertion-reason';
import FIBQuestion from './fill-in-blank';
import IntegerQuestion from './integer-type';
import MatrixMatchQuestion from './matrixmatch';
import MCQQuestion from './mcq';
import { TranslatedQuestion } from './schema';
import SCQQuestion from './scq';
import SubjectiveQuestion from './subjective';
import TrueFalseQuestion from './true-false';
import { FeedbackMode, LanguageCode } from './types';

export default function Question({
  question,
  mode,
  language,
  allowReattempt,
  getTimeStamp,
  questionId,
  selected,
  submitted,
  setSelected,
  setSubmitted,
  multipleSelected,
  setMultipleSelected,
  finished,
}: {
  question: TranslatedQuestion;
  mode: FeedbackMode;
  language: LanguageCode;
  allowReattempt: boolean;
  getTimeStamp: () => number;
  questionId: string;
  selected?: number | null;
  submitted?: boolean;
  setSelected: (selected: number | null) => void;
  setSubmitted: (submitted: boolean) => void;
  multipleSelected?: number[];
  setMultipleSelected: (selected: number[]) => void;
  finished: boolean;
}) {
  if (question.type === 'scq') {
    return (
      <SCQQuestion
        question={question}
        language={language}
        mode={mode}
        allowReattempt={allowReattempt}
        getTimeStamp={getTimeStamp}
        questionId={questionId}
        selected={selected}
        submitted={submitted}
        setSelected={setSelected}
        setSubmitted={setSubmitted}
        finished={finished}
      />
    );
  } else if (question.type === 'subjective') {
    return (
      <SubjectiveQuestion
        question={question}
        language={language}
        mode={mode}
        allowReattempt={allowReattempt}
        getTimeStamp={getTimeStamp}
        questionId={questionId}
      />
    );
  } else if (question.type === 'mcq') {
    return (
      <MCQQuestion
        question={question}
        language={language}
        mode={mode}
        allowReattempt={allowReattempt}
        getTimeStamp={getTimeStamp}
        questionId={questionId}
        selected={multipleSelected}
        submitted={submitted}
        setSelected={setMultipleSelected}
        setSubmitted={setSubmitted}
      />
    );
  } else if (question.type === 'integerType') {
    return (
      <IntegerQuestion
        question={question}
        language={language}
        mode={mode}
        allowReattempt={allowReattempt}
        getTimeStamp={getTimeStamp}
        questionId={questionId}
        selected={selected}
        submitted={submitted}
        setSelected={setSelected}
        setSubmitted={setSubmitted}
      />
    );
  } else if (question.type === 'fillInTheBlank') {
    return (
      <FIBQuestion
        question={question}
        language={language}
        mode={mode}
        allowReattempt={allowReattempt}
        getTimeStamp={getTimeStamp}
        questionId={questionId}
      />
    );
  } else if (question.type === 'trueFalse') {
    return (
      <TrueFalseQuestion
        question={question}
        language={language}
        mode={mode}
        allowReattempt={allowReattempt}
        getTimeStamp={getTimeStamp}
        questionId={questionId}
        selected={selected}
        submitted={submitted}
        setSelected={setSelected}
        setSubmitted={setSubmitted}
      />
    );
  } else if (question.type === 'assertionReason') {
    return (
      <ARQuestion
        question={question}
        language={language}
        mode={mode}
        allowReattempt={allowReattempt}
        getTimeStamp={getTimeStamp}
        questionId={questionId}
        selected={selected}
        submitted={submitted}
        setSelected={setSelected}
        setSubmitted={setSubmitted}
      />
    );
  } else if (question.type === 'matrixMatch') {
    return (
      <MatrixMatchQuestion
        question={question}
        language={language}
        mode={mode}
        allowReattempt={allowReattempt}
        getTimeStamp={getTimeStamp}
        questionId={questionId}
        selected={selected}
        submitted={submitted}
        setSelected={setSelected}
        setSubmitted={setSubmitted}
      />
    );
  } else {
    return <>Unknown Question Type</>;
  }
}
