export function SubmitStatus({ correct }: { correct: boolean }) {
  return (
    <div className="container">
      <div className="row p-2">
        <div className="col-12">
          {correct ? (
            <h4 className="bg-success p-2 text-white rounded">Perfect!</h4>
          ) : (
            <h4 className="bg-wrong text-white rounded p-2">Missed it!</h4>
          )}
        </div>
      </div>
    </div>
  );
}
