import { useEffect, useMemo, useState } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { Cloud } from '../cloud';
import Question from './questions/question';
import { TranslatedQuestion } from './questions/schema';
import type { FeedbackMode, LanguageCode } from './questions/types';
import React from 'react';
import { Device } from '../device';
import invariant from 'tiny-invariant';
import { Loading3D } from './loading';

export default function PractiseSession({
  questionIds,
  language,
  showTimer,
  feedbackMode,
  allowReattempt,
}: {
  questionIds: string[];
  language: LanguageCode;
  showTimer: boolean;
  feedbackMode: FeedbackMode;
  allowReattempt: boolean;
}) {
  const [renderStartDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [finishDate, setFinishDate] = useState<Date | null>(null);
  const [currIndex, setCurrIndex] = useState(-1);
  const [flagged, setFlagged] = useState<boolean[]>(new Array<boolean>(questionIds.length).fill(false));
  const [showStatus, setShowStatus] = useState(false);
  const [firstFetchDone, setFirstFetchDone] = useState(false);
  const [batchNumber, setBatchNumber] = useState(0); // Fetch questions by batches of 10 from index [10x+1, 10(x+1)] after 1st question.
  const [questions, setQuestions] = useState<(TranslatedQuestion | null)[]>(
    new Array<TranslatedQuestion | null>(questionIds.length).fill(null),
  );
  const [refetchSameBatch, setRefetchSameBatch] = useState(0);
  const [selectedMap, setSelectedMap] = useState<Map<string, number | null>>(new Map());
  const [multipleChoiceSelectedMap, setMultipleChoiceSelectedMap] = useState<Map<string, number[]>>(new Map());
  const [submittedMap, setSubmittedMap] = useState<Map<string, boolean>>(new Map());
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    // For all questions, set the selectedMap and submittedMap to null and false respectively.
    const newSelectedMap = new Map<string, number | null>();
    const newMultipleChoiceSelectedMap = new Map<string, number[]>();
    const newSubmittedMap = new Map<string, boolean>();
    questionIds.forEach((id) => {
      newMultipleChoiceSelectedMap.set(id, []);
      newSelectedMap.set(id, null);
      newSubmittedMap.set(id, false);
    });
    setSelectedMap(newSelectedMap);
    setSubmittedMap(newSubmittedMap);
    setMultipleChoiceSelectedMap(newMultipleChoiceSelectedMap);
  }, [questionIds]);

  // Do the first fetch. Can we do streaming here?
  useEffect(() => {
    async function fetchFirstQuestion() {
      if (firstFetchDone || questionIds.length === 0) {
        return;
      }
      console.log('Doing first fetch');
      Cloud.loadQuestion(questionIds[0]).then((q) => {
        setQuestions((prev) => {
          const newQuestions = [...prev];
          newQuestions[0] = q;
          return newQuestions;
        });
        setFirstFetchDone(true);
      });
    }

    fetchFirstQuestion();
  });

  // TODO(Utkarsh): Handle the case when promise.all fails.
  useEffect(() => {
    async function fetchNextBatch() {
      if (!firstFetchDone || batchNumber * 10 + 1 >= questionIds.length) {
        return;
      }

      console.log('Doing batch fetch', batchNumber);
      const start = 10 * batchNumber + 1;
      const end = Math.min(10 * (batchNumber + 1), questionIds.length);
      const batch = questionIds.slice(start, end);
      const promises = batch.map((id) => Cloud.loadQuestion(id));
      try {
        const questions = await Promise.all(promises);
        setQuestions((prev) => {
          const newQuestions = [...prev];
          questions.forEach((q, index) => {
            newQuestions[start + index] = q;
          });
          return newQuestions;
        });
        setBatchNumber(batchNumber + 1);
      } catch (e) {
        console.error('Error fetching questions', e);
        setRefetchSameBatch(refetchSameBatch + 1);
      }
    }

    fetchNextBatch();
  }, [batchNumber, firstFetchDone, questionIds, refetchSameBatch]);

  // Reattempt is only supported in immediate feedback mode.
  invariant(!allowReattempt || feedbackMode === 'immediate', 'Reattempt is only supported in immediate feedback mode');

  const startSession = () => {
    setStartDate(new Date());
    Device.sendPractiseEvent({
      type: 'start-session',
      questionIds,
      language,
      showTimer,
      feedbackMode,
      allowReattempt,
      timestamp: dateDiffISeconds(renderStartDate, new Date()),
    });
    // Visit question 0.
    Device.sendPractiseEvent({
      type: 'visit-question',
      questionId: questionIds[0],
      timestamp: dateDiffISeconds(renderStartDate, new Date()),
    });
    setCurrIndex(0);
  };

  if (currIndex === -1) {
    return (
      <div className="text-center my-auto">
        <button onClick={startSession} className="btn btn-warning">
          Start Session
        </button>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex justify-content-between py-3">
          <button
            className="btn bg-yellow btn-sm"
            onClick={(e) => {
              console.log('prev-question');
              // Exit current question and visit previous question.
              Device.sendPractiseEvent({
                type: 'exit-question',
                questionId: questionIds[currIndex],
                timestamp: dateDiffISeconds(renderStartDate, new Date()),
              });
              Device.sendPractiseEvent({
                type: 'visit-question',
                questionId: questionIds[currIndex - 1],
                timestamp: dateDiffISeconds(renderStartDate, new Date()),
              });
              setCurrIndex(currIndex - 1);
            }}
            disabled={currIndex <= 0}
          >
            {'<<'}
          </button>
          <button className="btn" disabled={finished}>
            <i
              className={`bi ${flagged[currIndex] ? 'bi-flag-fill' : 'bi-flag'}`}
              onClick={() => {
                if (flagged[currIndex]) {
                  Device.sendPractiseEvent({
                    type: 'unflag-question',
                    questionId: questionIds[currIndex],
                    timestamp: dateDiffISeconds(renderStartDate, new Date()),
                  });
                } else {
                  Device.sendPractiseEvent({
                    type: 'flag-question',
                    questionId: questionIds[currIndex],
                    timestamp: dateDiffISeconds(renderStartDate, new Date()),
                  });
                }

                const newFlagged = [...flagged];
                newFlagged[currIndex] = !newFlagged[currIndex];
                setFlagged(newFlagged);
              }}
            ></i>
          </button>
          {showTimer && <StopWatch start={startDate} finishedDate={finishDate} />}
          <button className="btn">
            <i
              className={`bi bi-journal`}
              onClick={() => {
                if (showStatus) {
                  // It must be exiting journal mode and visiting currIndex Question
                  Device.sendPractiseEvent({
                    type: 'exit-journal',
                    timestamp: dateDiffISeconds(renderStartDate, new Date()),
                  });
                  Device.sendPractiseEvent({
                    type: 'visit-question',
                    questionId: questionIds[currIndex],
                    timestamp: dateDiffISeconds(renderStartDate, new Date()),
                  });
                }
                if (!showStatus) {
                  // It must be entering journal mode and exiting current question.
                  Device.sendPractiseEvent({
                    type: 'visit-journal',
                    timestamp: dateDiffISeconds(renderStartDate, new Date()),
                  });
                  Device.sendPractiseEvent({
                    type: 'exit-question',
                    questionId: questionIds[currIndex],
                    timestamp: dateDiffISeconds(renderStartDate, new Date()),
                  });
                }

                setShowStatus(!showStatus);
              }}
            ></i>
          </button>
          <button
            className="btn bg-yellow btn-sm"
            onClick={(e) => {
              console.log('next-question');
              // Exit current question and visit next question.
              Device.sendPractiseEvent({
                type: 'exit-question',
                questionId: questionIds[currIndex],
                timestamp: dateDiffISeconds(renderStartDate, new Date()),
              });
              Device.sendPractiseEvent({
                type: 'visit-question',
                questionId: questionIds[currIndex + 1],
                timestamp: dateDiffISeconds(renderStartDate, new Date()),
              });
              setCurrIndex(currIndex + 1);
            }}
            disabled={currIndex === questionIds.length - 1}
          >
            {'>>'}
          </button>
        </div>
      </div>
      {showStatus && (
        <div className="container">
          <div className="d-flex-wrap gap-x-4">
            {questionIds.map((id, index) => {
              return (
                <span
                  key={index}
                  className={`p-2 m-2 border`}
                  onClick={() => {
                    console.log('Clicked', index);
                    setShowStatus(false);
                    // Visit event for the question and exit journal event.
                    Device.sendPractiseEvent({
                      type: 'visit-question',
                      questionId: questionIds[index],
                      timestamp: dateDiffISeconds(renderStartDate, new Date()),
                    });
                    Device.sendPractiseEvent({
                      type: 'exit-journal',
                      timestamp: dateDiffISeconds(renderStartDate, new Date()),
                    });
                    if (index === currIndex) {
                      return;
                    }
                    setCurrIndex(index);
                  }}
                >
                  <i className="bi bi-bookmark bg-yellow"></i>
                </span>
              );
            })}
          </div>
          <div className="row my-2 p-2">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setFinished(true);
                setFinishDate(new Date());
              }}
              disabled={finished}
            >
              Finish Session
            </button>
          </div>
        </div>
      )}
      {!showStatus && questions[currIndex] === null && <Loading3D />}
      {!showStatus && questions[currIndex] !== null && (
        <Question
          // @ts-ignore
          question={questions[currIndex]}
          language={language}
          mode="immediate"
          allowReattempt={allowReattempt}
          getTimeStamp={() => dateDiffISeconds(renderStartDate, new Date())}
          questionId={questionIds[currIndex]}
          selected={selectedMap.get(questionIds[currIndex])}
          setSelected={(selected: number | null) => {
            setSelectedMap(new Map(selectedMap.set(questionIds[currIndex], selected)));
          }}
          multipleSelected={multipleChoiceSelectedMap.get(questionIds[currIndex])}
          setMultipleSelected={(selected: number[]) => {
            setMultipleChoiceSelectedMap(new Map(multipleChoiceSelectedMap.set(questionIds[currIndex], selected)));
          }}
          submitted={submittedMap.get(questionIds[currIndex])}
          setSubmitted={(submitted: boolean) => {
            setSubmittedMap(new Map(submittedMap.set(questionIds[currIndex], submitted)));
          }}
          finished={finished}
        />
      )}
    </div>
  );
}

function StopWatch({ start, finishedDate }: { start: Date; finishedDate: Date | null }) {
  const stopwatchOffset = new Date();
  const diff = dateDiffISeconds(start, stopwatchOffset);
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + diff);

  let { seconds, minutes, hours } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });
  if (finishedDate) {
    let duration = dateDiffISeconds(start, finishedDate);
    hours = Math.floor(duration / 3600);
    minutes = Math.floor((duration % 3600) / 60);
    seconds = duration % 60;
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <div>
        <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
    </div>
  );
}

function dateDiffISeconds(a: Date, b: Date) {
  const _MS_PER_SEC = 1000;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes(), a.getSeconds());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes(), b.getSeconds());

  return Math.round((utc2 - utc1) / _MS_PER_SEC);
}
