/*
 * --------------------------------------------------------------------------- *
 * File: theorycontent.tsx                                                     *
 * Project: content-render                                                     *
 * Created Date: 17 Mar 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Fri Mar 17 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import React from 'react';

function TheoryContent(params: any) {
  const content = params.content;

  return (
    <div className="container">
      <div className="row p-2">
        <div className="col-1 pt-4">
          <h2>{content[0] ? content[0]['Content Quantum Title'] : ''}</h2>
        </div>
        {content.map((cr: any) => (
          <div className="col-12">
            {cr['Concept Title'] && (
              <div className="card pt-2 mt-4 bg-yellow">
                <div className="card-body">
                  <h5 className="card-title">{cr['Concept Title']}</h5>
                </div>
              </div>
            )}
            <div className="card pt-2 mt-4">
              <div className="card-body">
                <h6 className="card-subtitle mb-2">{cr['Subtitle']}</h6>
                {/* <h6 className="card-subtitle mb-2 text-muted">{cr['Theory Type']}</h6> */}
                <hr />
                <div className="card-text" dangerouslySetInnerHTML={{ __html: cr['Content'] }}></div>
                <div className="card-footer d-flex justify-content-around">
                  <button className="btn">
                    <i className="bi bi-check2-circle"></i>
                  </button>
                  <button className="btn">
                    <i className="bi bi-bookmark-star"></i>
                  </button>
                  <button className="btn">
                    <i className="bi bi-question-circle"></i>
                  </button>
                  <button className="btn">
                    <i className="bi bi-exclamation-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TheoryContent;
