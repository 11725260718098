/*
 * --------------------------------------------------------------------------- *
 * File: useKatex.ts                                                           *
 * Project: katex-render                                                       *
 * Created Date: 14 Mar 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Tue Mar 14 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import { useEffect } from 'react';

export default function useKatex() {
  useEffect(() => {
    const mathElements = document.getElementsByClassName('math');
    const macros = {};
    for (let i = 0; i < mathElements.length; i++) {
      const mathElement = mathElements[i];
      const firstChild = mathElement.firstChild;
      if (firstChild === null || firstChild === undefined) {
        continue;
      }
      const texText = firstChild;
      // @ts-ignore
      if (texText === null || texText === undefined || !texText.data) {
        continue;
      }
      // @ts-ignore
      if (typeof texText.data === 'string') {
        if (mathElement.tagName === 'SPAN') {
          // @ts-ignore
          katex.render(texText.data, mathElement, {
            displayMode: mathElement.classList.contains('display'),
            throwOnError: false,
            macros: macros,
            fleqn: false,
          });
        }
      }
    }
  });
}
