import { MatrixMatchTranslatedQuestion } from './schema';
import { useEffect, useMemo, useState } from 'react';
import useKatex from '../../useKatex';
import { LanguageCode, FeedbackMode } from './types';
import { TaggedHTML } from './tagged-html';
import { SubmitStatus } from './submit-status';
import invariant from 'tiny-invariant';
import { Device } from '../../device';
import { Loading3D } from '../loading';

function numberToUpperCaseAlphabet(num: number) {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  let result = '';
  while (num > 0) {
    let remainder = (num - 1) % 26;
    result = alphabet[remainder] + result;
    num = Math.floor((num - 1) / 26);
  }
  return result;
}

function matrixOptions(matrix: number[][]) {
  const correctAnswer = matrix
    .map((row, index) => `${index + 1}: ${row.map((x) => String.fromCharCode(64 + x)).join(', ')}`)
    .join(' | ');

  // Swap the first two rows
  const swapRows = (matrix: number[][], row1: number, row2: number) => {
    const temp = matrix[row1];
    matrix[row1] = matrix[row2];
    matrix[row2] = temp;
    return matrix;
  };

  const matAnsToString = (matrix: number[][]) => {
    return matrix
      .map((row, index) => `${index + 1}: ${row.map((x) => String.fromCharCode(64 + x)).join(', ')}`)
      .join(' | ');
  };

  const swappedOne = matAnsToString(swapRows(matrix, 0, 1));
  const swappedTwo = matAnsToString(swapRows(matrix, 1, 2));
  const swappedThree = matAnsToString(swapRows(matrix, 0, 2));
  return {
    options: [correctAnswer, swappedOne, swappedTwo, swappedThree],
    correctAnswer: 0,
  };
}

export default function MatrixMatchQuestion({
  question,
  mode,
  language,
  allowReattempt,
  getTimeStamp,
  questionId,
  selected,
  submitted,
  setSelected,
  setSubmitted,
}: {
  question: MatrixMatchTranslatedQuestion;
  mode: FeedbackMode;
  language: LanguageCode;
  allowReattempt: boolean;
  getTimeStamp: () => number;
  questionId: string;
  selected?: number | null;
  submitted?: boolean;
  setSelected: (selected: number | null) => void;
  setSubmitted: (submitted: boolean) => void;
}) {
  const [optionsMap, setOptionsMap] = useState<Map<string, { options: string[]; correctAnswer: number }>>(new Map());

  useEffect(() => {
    setOptionsMap((prev) => {
      return prev.get(questionId) !== undefined ? prev : new Map(prev.set(questionId, matrixOptions(question.mat_ans)));
    });
  }, [questionId]);

  if (submitted === undefined || selected === undefined) {
    return <Loading3D />;
  }

  const colA = question.translations.find((t) => t.lang === language)?.colA || question.colA;
  const colB = question.translations.find((t) => t.lang === language)?.colB || question.colB;
  const solution = question.translations.find((t) => t.lang === language)?.sol || question.sol;

  const matrixAns = question.mat_ans
    .map((row, index) => `${index + 1}: ${row.map((x) => String.fromCharCode(64 + x)).join(', ')}`)
    .join(' | ');

  if (optionsMap.get(questionId) === undefined) {
    return <Loading3D />;
  }
  // @ts-ignore
  const { options, correctAnswer } = optionsMap.get(questionId);

  const chooseOption = (index: number) => {
    if (submitted) {
      return;
    }

    Device.sendPractiseEvent({
      type: 'toggle-answer',
      questionId,
      toggleOption: index + 1,
      questionType: question.type,
      correctAnswer: [correctAnswer],
      timestamp: getTimeStamp(),
    });
    setSelected(index + 1);
  };

  const isAnswerCorrect = (chosenIndex: number | null | undefined) => {
    if (chosenIndex === null || chosenIndex === undefined) {
      return false;
    }
    return chosenIndex === correctAnswer;
  };

  const correct = isAnswerCorrect(selected);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-6">
            {colA.map((item, index) => {
              return <TaggedHTML key={index} html={item} tag={`${index + 1}`} />;
            })}
          </div>
          <div className="col-6">
            {colB.map((item, index) => {
              return <TaggedHTML key={index} html={item} tag={`${numberToUpperCaseAlphabet(index + 1)}`} />;
            })}
          </div>
        </div>
        {options.map((option: any, index: number) => (
          <div className="row m-2">
            <div
              key={index}
              className={
                'col-12 border py-2 rounded border-2' +
                (submitted &&
                  (isAnswerCorrect(index + 1) ? ' border-success' : ' ') +
                    (selected === index + 1 && !correct ? ' border-danger' : ' ')) +
                (!submitted && selected === index + 1 ? ' border-primary' : ' ')
              }
              onClick={(e) => {
                chooseOption(index);
              }}
              dangerouslySetInnerHTML={{ __html: option }}
            ></div>
          </div>
        ))}
        <div className="row">
          <div className="col-12 p-3">
            <button
              className="btn btn-primary"
              disabled={selected === null || submitted}
              onClick={() => {
                invariant(selected !== null, 'selected should not be null');
                invariant(selected !== undefined, 'selected should not be undefined');
                Device.sendPractiseEvent({
                  type: 'submit-answer',
                  questionId,
                  selectedOptions: [selected],
                  questionType: question.type,
                  correctAnswer: [correctAnswer],
                  timestamp: getTimeStamp(),
                });
                setSubmitted(true);
              }}
            >
              Submit
            </button>
            {submitted && allowReattempt && (
              <button
                className="btn btn-primary"
                disabled={!submitted}
                onClick={() => {
                  invariant(selected !== null, 'selected should not be null');
                  invariant(selected !== undefined, 'selected should not be undefined');
                  invariant(submitted, 'submitted should be true');
                  Device.sendPractiseEvent({
                    type: 'retry-question',
                    questionId,
                    timestamp: getTimeStamp(),
                  });
                  setSelected(null);
                  setSubmitted(false);
                }}
              >
                Retry
              </button>
            )}
          </div>
        </div>
        {submitted && mode === 'immediate' && <SubmitStatus correct={correct} />}
        {submitted && mode === 'immediate' && <TaggedHTML html={`<p>${matrixAns}</p>`} tag="Answer" />}
        {submitted && mode === 'immediate' && <TaggedHTML html={solution} tag="Solution" />}
      </div>
    </>
  );
}
