import { useEffect, useState } from 'react';
import { SCQTranslatedQuestion } from './schema';
import useKatex from '../../useKatex';
import { LanguageCode, FeedbackMode } from './types';
import { TaggedHTML } from './tagged-html';
import { SubmitStatus } from './submit-status';
import { Device } from '../../device';
import invariant from 'tiny-invariant';
import { Loading3D } from '../loading';
import { WarnMessage } from '../warn-message';

export default function SCQQuestion({
  question,
  mode,
  language,
  allowReattempt,
  getTimeStamp,
  questionId,
  selected,
  submitted,
  setSelected,
  setSubmitted,
  finished,
}: {
  question: SCQTranslatedQuestion;
  mode: FeedbackMode;
  language: LanguageCode;
  allowReattempt: boolean;
  getTimeStamp: () => number;
  questionId: string;
  selected?: number | null;
  submitted?: boolean;
  setSelected: (selected: number | null) => void;
  setSubmitted: (submitted: boolean) => void;
  finished: boolean;
}) {
  useKatex();
  const stmt = question.translations.find((t) => t.lang === language)?.stmt || question.stmt;
  const options = question.translations.find((t) => t.lang === language)?.options || question.options;
  const solution = question.translations.find((t) => t.lang === language)?.sol || question.sol;
  const answer = question.translations.find((t) => t.lang === language)?.obj_ans || question.obj_ans;

  if (submitted === undefined || selected === undefined) {
    return <Loading3D />;
  }

  const chooseOption = (index: number) => {
    if (submitted || finished) {
      return;
    }

    // Send a message for the selected option.
    Device.sendPractiseEvent({
      type: 'toggle-answer',
      questionId,
      toggleOption: index + 1,
      questionType: question.type,
      correctAnswer: [question.obj_ans],
      timestamp: getTimeStamp(),
    });
    setSelected(index + 1);
  };

  const isAnswerCorrect = (chosenIndex: number | null) => {
    if (chosenIndex === null) {
      return false;
    }
    return chosenIndex === answer;
  };

  const correct = isAnswerCorrect(selected);

  const retryAction = () => {
    Device.sendPractiseEvent({ type: 'retry-question', questionId, timestamp: getTimeStamp() });
    setSelected(null);
    setSubmitted(false);
  };

  const submitAction = () => {
    invariant(selected !== null, 'selected should not be null');
    Device.sendPractiseEvent({
      type: 'submit-answer',
      questionId,
      selectedOptions: [selected],
      questionType: question.type,
      correctAnswer: [question.obj_ans],
      timestamp: getTimeStamp(),
    });
    setSubmitted(true);
  };
  return (
    <>
      <div className="container">
        {finished && selected === null && <WarnMessage warning="Not Attempted" />}
        <div className="row">
          <div className="col-12 p-3" dangerouslySetInnerHTML={{ __html: stmt }}></div>
        </div>
        {options.map((option: any, index: number) => (
          <div className="row m-2">
            <div
              key={index}
              className={
                'col-12 border py-2 rounded border-2' +
                (submitted &&
                  (isAnswerCorrect(index + 1) ? ' border-success' : ' ') +
                    (selected === index + 1 && !correct ? ' border-danger' : ' ')) +
                (!submitted && selected === index + 1 ? ' border-primary' : ' ')
              }
              onClick={(e) => {
                chooseOption(index);
              }}
              dangerouslySetInnerHTML={{ __html: option }}
            ></div>
          </div>
        ))}
        <div className="row">
          <div className="col-12 p-3">
            <button
              className="btn btn-primary"
              disabled={finished || selected === null || submitted}
              onClick={submitAction}
            >
              Submit
            </button>
            {submitted && allowReattempt && (
              <button className="btn btn-primary" onClick={retryAction} disabled={finished}>
                Retry
              </button>
            )}
          </div>
        </div>
      </div>
      {submitted && mode === 'immediate' && <SubmitStatus correct={correct} />}
      {submitted && mode === 'immediate' && <TaggedHTML html={solution} tag="Solution" />}
    </>
  );
}
