import { FIBTranslatedQuestion } from './schema';
import { useState } from 'react';
import useKatex from '../../useKatex';
import { LanguageCode, FeedbackMode } from './types';
import { TaggedHTML } from './tagged-html';

export default function FIBQuestion({
  question,
  mode,
  language,
  allowReattempt,
  getTimeStamp,
  questionId: string,
}: {
  question: FIBTranslatedQuestion;
  mode: FeedbackMode;
  language: LanguageCode;
  allowReattempt: boolean;
  getTimeStamp: () => number;
  questionId: string;
}) {
  useKatex();
  const [showSolution, setShowSolution] = useState(false);

  const stmt = question.translations.find((t) => t.lang === language)?.stmt || question.stmt;
  const solution = question.translations.find((t) => t.lang === language)?.sol || question.sol;
  const answer = question.translations.find((t) => t.lang === language)?.ans || question.ans;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 border p-3 rounded bg-yellow">
            <h2>Fill in the Blank</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-3" dangerouslySetInnerHTML={{ __html: stmt }}></div>
        </div>
        <div className="row">
          <div className="col-12 p-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                setShowSolution(!showSolution);
              }}
            >
              {showSolution ? 'Hide' : 'Show'} Answer
            </button>
          </div>
        </div>
      </div>
      {showSolution && mode === 'immediate' && <TaggedHTML html={answer} tag="Answer" />}
      {showSolution && mode === 'immediate' && <TaggedHTML html={solution} tag="Solution" />}
    </>
  );
}
