import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';

export const Loading2D = () => {
  return (
    <div className="loading-container">
      <div className="loading-circle loading-circle-1"></div>
      <div className="loading-circle loading-circle-2"></div>
      <div className="loading-circle loading-circle-3"></div>
      <div className="loading-circle loading-circle-4"></div>
    </div>
  );
};

const Box = (props: any) => {
  const mesh = useRef();

  useFrame(() => {
    // @ts-ignore
    mesh.current.rotation.x += 0.01;
    // @ts-ignore
    mesh.current.rotation.y += 0.01;
  });

  return (
    <mesh {...props} ref={mesh}>
      <boxBufferGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={props.color} />
    </mesh>
  );
};

export const Loading3D = () => {
  return (
    <Canvas>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Box position={[-1.2, 0, 0]} color="yellow" />
      <Box position={[1.2, 0, 0]} color="red" />
    </Canvas>
  );
};
