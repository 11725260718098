/*
 * --------------------------------------------------------------------------- *
 * File: App.tsx                                                               *
 * Project: content-render                                                     *
 * Created Date: 10 Mar 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Sat Mar 18 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import React, { useEffect } from 'react';
import './App.css';
import { Cloud } from './cloud';
import { Device } from './device';
import ARQuestion from './components/questions/assertion-reason';
import FIBQuestion from './components/questions/fill-in-blank';
import IntegerQuestion from './components/questions/integer-type';
import MatrixMatchQuestion from './components/questions/matrixmatch';
import MCQQuestion from './components/questions/mcq';
import { questionTranslatedSchema } from './components/questions/schema';
import SCQQuestion from './components/questions/scq';
import SubjectiveQuestion from './components/questions/subjective';
import TrueFalseQuestion from './components/questions/true-false';
import TheoryContent from './theorycontent';
import useKatex from './useKatex';
import PractiseSession from './components/practise-session';
import { lang } from 'moment';

function App() {
  const [input, setInput] = React.useState<any>({});
  const [content, setContent] = React.useState<any>({});

  const loadContent = async (quantum: string, batch: string, language: string) => {
    const list = await Cloud.loadQuantum(quantum);
    Device.sendMessage(JSON.stringify(list));
    const contentPromise = list
      .map(
        (c) =>
          `https://${Cloud.CONTENT_SUB_DOMAIN}.${Cloud.BASE_DOMAIN}/contents/${batch}/${language}${
            c.split('::')[1]
          }.json`,
      )
      .map((url) => Cloud.fetchContent(url));

    const allContent = await Promise.all(contentPromise);
    // Identify the title and subtitles that should be rendered. Crush the others
    var lastTitle = '';
    var lastSubtitle = '';

    for (let i = 0; i < allContent.length; i++) {
      Device.sendMessage(`${allContent[i]['Concept Title']} - ${lastTitle}`);
      if (allContent[i]['Concept Title'] === lastTitle) {
        allContent[i]['Concept Title'] = '';
      } else {
        lastTitle = allContent[i]['Concept Title'];
        lastSubtitle = '';
      }
      if (allContent[i]['Subtitle'] === lastSubtitle) {
        allContent[i]['Subtitle'] = '';
      } else {
        lastSubtitle = allContent[i]['Subtitle'];
      }
    }
    Device.sendMessage(JSON.stringify(allContent.map((c) => c['Concept Title'])));
    Device.sendMessage(JSON.stringify(allContent.map((c) => c['Subtitle'])));
    setContent(allContent);
  };

  const loadQuestion = async (questionId: string, language: string) => {
    Device.sendMessage(`Loading question ${questionId}`);
    const c = await Cloud.loadQuestion(questionId);

    console.log(c.type);
    setContent(c);
  };

  // Check the ID, type and language. Fetch the elements from the server and render them.
  // Use the special headers in the API calls to make sure it passes through the CF Functions.
  useEffect(() => {
    Device.sendMessage('App Loaded');
    Cloud.TOKEN = localStorage.getItem('token') || '';
    const params = {
      quantum: localStorage.getItem('quantum') || '',
      question: localStorage.getItem('question') || '',
      language: localStorage.getItem('language') || 'en',
      batch: localStorage.getItem('batch') || '',
    };
    Device.sendMessage(JSON.stringify(params));
    setInput(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Device.sendMessage('Second Loaded');
    Device.sendMessage(JSON.stringify(input));
    if (input.quantum && input.language && input.batch) {
      let l = '';
      if (input.language !== 'en') {
        l = `${input.language}/`;
      }
      loadContent(input.quantum, input.batch, l);
    } else if (input.question && input.language) {
      console.log(`Loading question ${input.question}`);
      loadQuestion(input.question, input.language);
    }
  }, [input]);

  useKatex();

  if (input.quantum && content.length) {
    console.log(content);
    Device.sendMessage('Quantup');
    return <TheoryContent content={content} />;
  } else if (input.question && content && content.type) {
    console.log({ content });
    const contentTyped = questionTranslatedSchema.parse(content);
  }

  return (
    <PractiseSession
      questionIds={[
        '6405e4f3fe1c3b7e1ec22ee5',
        '6405e4f3fe1c3b7e1ec22ee6',
        '6405e4f3fe1c3b7e1ec22eed',
        '6405e4f3fe1c3b7e1ec22f0f',
        '6405e4f3fe1c3b7e1ec22ef0',
        '6405e4f3fe1c3b7e1ec22ef3',
      ]}
      language={input.language}
      showTimer={true}
      feedbackMode="immediate"
      allowReattempt={true}
    />
  );
}

export default App;
