import { IntegerTranslatedQuestion } from './schema';
import { useState } from 'react';
import useKatex from '../../useKatex';
import { LanguageCode, FeedbackMode } from './types';
import { TaggedHTML } from './tagged-html';
import invariant from 'tiny-invariant';
import { Device } from '../../device';
import { Loading3D } from '../loading';

export default function IntegerQuestion({
  question,
  mode,
  language,
  allowReattempt,
  getTimeStamp,
  questionId,
  selected,
  setSelected,
  submitted,
  setSubmitted,
}: {
  question: IntegerTranslatedQuestion;
  mode: FeedbackMode;
  language: LanguageCode;
  allowReattempt: boolean;
  getTimeStamp: () => number;
  questionId: string;
  selected?: number | null;
  submitted?: boolean;
  setSelected: (selected: number | null) => void;
  setSubmitted: (submitted: boolean) => void;
}) {
  useKatex();
  const stmt = question.translations.find((t) => t.lang === language)?.stmt || question.stmt;
  const solution = question.translations.find((t) => t.lang === language)?.sol || question.sol;
  const answer = question.translations.find((t) => t.lang === language)?.int_ans || question.int_ans;

  if (submitted === undefined || selected === undefined) {
    return <Loading3D />;
  }

  const correct = selected === answer;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 border p-3 rounded bg-yellow">
            <h2>Integer Answer</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-3" dangerouslySetInnerHTML={{ __html: stmt }}></div>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="row m-2">
            <div className="col-12">
              <label htmlFor="answer">Answer</label>
              <input
                type="number"
                className={'form-control' + (submitted && (correct ? ' border-success' : 'border-danger '))}
                disabled={submitted}
                value={selected || 0}
                onChange={(e) => {
                  if (submitted) return;
                  setSelected(parseInt(e.target.value));
                }}
              />
            </div>
          </div>
          <div className="col-12 p-3">
            <button
              className="btn btn-primary"
              disabled={selected === null || submitted}
              onClick={() => {
                setSubmitted(true);
                setSelected(selected);
                invariant(selected !== null, 'selected should not be null');
                Device.sendPractiseEvent({
                  type: 'submit-int-answer',
                  questionId,
                  answer: selected,
                  questionType: question.type,
                  correctAnswer: question.int_ans,
                  timestamp: getTimeStamp(),
                });
              }}
            >
              Submit
            </button>
            {submitted && allowReattempt && (
              <button
                className="btn btn-primary"
                disabled={!submitted}
                onClick={() => {
                  invariant(selected !== null, 'selected should not be null');
                  invariant(submitted, 'submitted should be true');
                  Device.sendPractiseEvent({
                    type: 'retry-question',
                    questionId,
                    timestamp: getTimeStamp(),
                  });
                  setSelected(null);
                  setSubmitted(false);
                }}
              >
                Retry
              </button>
            )}
          </div>
        </form>
      </div>
      {submitted && mode === 'immediate' && (
        <div className="container">
          <div className="row">
            {correct ? (
              <h4 className="p-3 m-2 bg-success text-white rounded">Perfect!</h4>
            ) : (
              <h4 className="p-3 m-2 bg-wrong text-white rounded">Missed it!</h4>
            )}
          </div>
        </div>
      )}
      {submitted && mode === 'immediate' && <TaggedHTML html={`<p>${answer}</p>`} tag="Answer" />}
      {submitted && mode === 'immediate' && <TaggedHTML html={solution} tag="Solution" />}
    </>
  );
}
