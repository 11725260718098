import { TFTranslatedQuestion } from './schema';
import { useEffect, useState } from 'react';
import useKatex from '../../useKatex';
import { LanguageCode, FeedbackMode } from './types';
import { SubmitStatus } from './submit-status';
import { TaggedHTML } from './tagged-html';
import { Device } from '../../device';
import invariant from 'tiny-invariant';
import { Loading3D } from '../loading';

export default function TrueFalseQuestion({
  question,
  mode,
  language,
  allowReattempt,
  getTimeStamp,
  questionId,
  selected,
  submitted,
  setSelected,
  setSubmitted,
}: {
  question: TFTranslatedQuestion;
  mode: FeedbackMode;
  language: LanguageCode;
  allowReattempt: boolean;
  getTimeStamp: () => number;
  questionId: string;
  selected?: number | null;
  submitted?: boolean;
  setSelected: (selected: number | null) => void;
  setSubmitted: (submitted: boolean) => void;
}) {
  useKatex();

  const stmt = question.translations.find((t) => t.lang === language)?.stmt || question.stmt;
  const solution = question.translations.find((t) => t.lang === language)?.sol || question.sol;
  const answer = question.tf_ans ? 1 : 2; // 1 is true & 2 is false
  const correct = selected === answer;

  if (submitted === undefined || selected === undefined) {
    return <Loading3D />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 border p-3 rounded bg-yellow">
            <h2>True False</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-3" dangerouslySetInnerHTML={{ __html: stmt }}></div>
        </div>
        <div className="row m-2">
          <div
            className={
              'col-12 border py-2 rounded border-2' +
              (submitted &&
                (answer === 1 ? ' border-success' : ' ') + (selected === 1 && !correct ? ' border-danger' : ' ')) +
              (!submitted && selected === 1 ? ' border-primary' : ' ')
            }
            onClick={(e) => {
              if (submitted) return;
              Device.sendPractiseEvent({
                type: 'toggle-answer',
                questionId,
                toggleOption: 1, // 1 is true & 2 is false
                questionType: question.type,
                correctAnswer: question.tf_ans ? [1] : [2],
                timestamp: getTimeStamp(),
              });
              setSelected(1);
            }}
          >
            True{' '}
          </div>
        </div>
        <div className="row m-2">
          <div
            className={
              'col-12 border py-2 rounded border-2' +
              (submitted &&
                (answer === 2 ? ' border-success' : ' ') + (selected === 2 && !correct ? ' border-danger' : ' ')) +
              (!submitted && selected === 2 ? ' border-primary' : ' ')
            }
            onClick={(e) => {
              if (submitted) return;
              Device.sendPractiseEvent({
                type: 'toggle-answer',
                questionId,
                toggleOption: 2,
                questionType: question.type,
                correctAnswer: question.tf_ans ? [1] : [2],
                timestamp: getTimeStamp(),
              });
              setSelected(2);
            }}
          >
            False
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-3">
            <button
              className="btn btn-primary"
              disabled={selected === null || selected === undefined || submitted}
              onClick={() => {
                invariant(selected !== null && selected !== undefined, 'selected should not be null or undefined');
                invariant(!submitted, 'submitted should not be true');
                Device.sendPractiseEvent({
                  type: 'submit-answer',
                  questionId,
                  selectedOptions: [selected],
                  questionType: question.type,
                  correctAnswer: [answer], // 1 is true & 2 is false
                  timestamp: getTimeStamp(),
                });
                setSubmitted(true);
              }}
            >
              Submit
            </button>
            {submitted && allowReattempt && (
              <button
                className="btn btn-primary"
                disabled={!submitted}
                onClick={() => {
                  invariant(selected !== null, 'selected should not be null');
                  invariant(selected !== undefined, 'selected should not be undefined');
                  invariant(submitted, 'submitted should be true');
                  Device.sendPractiseEvent({
                    type: 'retry-question',
                    questionId,
                    timestamp: getTimeStamp(),
                  });
                  setSelected(null);
                  setSubmitted(false);
                }}
              >
                Retry
              </button>
            )}
          </div>
        </div>
      </div>
      {submitted && mode === 'immediate' && <SubmitStatus correct={correct} />}
      {submitted && mode === 'immediate' && <TaggedHTML html={solution} tag="Solution" />}
    </>
  );
}
