import { z } from 'zod';

const commonSchema = z.object({
  source: z.string(),
  revise: z.boolean(),
  pyq: z.number() /* Implicit mapping: IITAdvanced = 1, IITMains = 2, NEET = 3 */,
  pyq_year: z.number() /* If 0 then not an exam */,
  pyq_details: z.string(),
});

const scqTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('scq'),
  stmt: z.string(),
  sol: z.string(),
  options: z.array(z.string()),
  obj_ans: z.number().min(0).max(5), // If it is 0 then the answer is not known.
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string(),
      sol: z.string(),
      options: z.array(z.string()).nullable(),
      obj_ans: z.number().min(0).max(5),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const mcqTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('mcq'),
  stmt: z.string(),
  sol: z.string(),
  options: z.array(z.string()),
  obj_ans: z.number().min(1).max(5).array(), // If len is 0 then the answer is not known.
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string(),
      sol: z.string(),
      options: z.array(z.string()),
      obj_ans: z.number().min(1).max(5).array(),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const subjectiveTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('subjective'),
  stmt: z.string(),
  sol: z.string(),
  ans: z.string(),
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string(),
      sol: z.string(),
      ans: z.string(),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const integerTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('integerType'),
  stmt: z.string(),
  sol: z.string(),
  int_ans: z.number(),
  ans_absent: z.boolean(),
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string(),
      sol: z.string(),
      int_ans: z.number(),
      ans_absent: z.boolean(),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const arTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('assertionReason'),
  assr: z.string(),
  rsn: z.string(),
  sol: z.string(),
  ar_ans: z.number().min(0).max(5),
  translations: z
    .object({
      lang: z.string(),
      assr: z.string(),
      rsn: z.string(),
      sol: z.string(),
      ar_ans: z.number().min(0).max(5),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const matrixMatchTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('matrixMatch'),
  colA: z.string().array(),
  colB: z.string().array(),
  sol: z.string(),
  ans_absent: z.boolean(),
  mat_ans: z.number().array().array(),
  stmt: z.string().nullable().optional(),
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string().nullable().optional(),
      colA: z.string().array(),
      colB: z.string().array(),
      sol: z.string(),
      ans_absent: z.boolean(),
      mat_ans: z.number().array().array(),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const tfTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('trueFalse'),
  stmt: z.string(),
  sol: z.string(),
  tf_ans: z.boolean(),
  ans_absent: z.boolean(),
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string(),
      sol: z.string(),
      tf_ans: z.boolean(),
      ans_absent: z.boolean(),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});


const fibTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('fillInTheBlank'),
  stmt: z.string(),
  sol: z.string(),
  ans: z.string(),
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string(),
      sol: z.string(),
      ans: z.string(),
    })
    .array(),
});

const questionTranslatedSchema = z.union([
  scqTranslatedSchema,
  mcqTranslatedSchema,
  subjectiveTranslatedSchema,
  integerTranslatedSchema,
  arTranslatedSchema,
  matrixMatchTranslatedSchema,
  tfTranslatedSchema,
  fibTranslatedSchema,
]);

const comprehensionTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  passage: z.string(),
  questions: questionTranslatedSchema.array(),
  translations: z
    .object({
      lang: z.string(),
      passage: z.string(),
      mode: z.union([z.literal('machine'), z.literal('human'), z.literal('semi')]),
    })
    .array(),
});

type SCQTranslatedQuestion = z.infer<typeof scqTranslatedSchema>;
type MCQTranslatedQuestion = z.infer<typeof mcqTranslatedSchema>;
type ARTranslatedQuestion = z.infer<typeof arTranslatedSchema>;
type MatrixMatchTranslatedQuestion = z.infer<typeof matrixMatchTranslatedSchema>;
type TFTranslatedQuestion = z.infer<typeof tfTranslatedSchema>;
type FIBTranslatedQuestion = z.infer<typeof fibTranslatedSchema>;
type SubjectiveTranslatedQuestion = z.infer<typeof subjectiveTranslatedSchema>;
type IntegerTranslatedQuestion = z.infer<typeof integerTranslatedSchema>;

type TranslatedQuestion = z.infer<typeof questionTranslatedSchema>;
type TranslatedComprehension = z.infer<typeof comprehensionTranslatedSchema>;

export {
  questionTranslatedSchema,
  comprehensionTranslatedSchema,
};

export type {
  TranslatedQuestion,
  SCQTranslatedQuestion,
  MCQTranslatedQuestion,
  ARTranslatedQuestion,
  MatrixMatchTranslatedQuestion,
  TFTranslatedQuestion,
  FIBTranslatedQuestion,
  SubjectiveTranslatedQuestion,
  IntegerTranslatedQuestion,
  TranslatedComprehension,
};
