import { ARTranslatedQuestion } from './schema';
import useKatex from '../../useKatex';
import { LanguageCode, FeedbackMode } from './types';
import { SubmitStatus } from './submit-status';
import { TaggedHTML } from './tagged-html';
import { Device } from '../../device';
import invariant from 'tiny-invariant';
import { Loading3D } from '../loading';

export default function ARQuestion({
  question,
  mode,
  language,
  allowReattempt,
  getTimeStamp,
  questionId,
  selected,
  submitted,
  setSelected,
  setSubmitted,
}: {
  question: ARTranslatedQuestion;
  mode: FeedbackMode;
  language: LanguageCode;
  allowReattempt: boolean;
  getTimeStamp: () => number;
  questionId: string;
  selected?: number | null;
  submitted?: boolean;
  setSelected: (selected: number | null) => void;
  setSubmitted: (submitted: boolean) => void;
}) {
  useKatex();

  const assr = question.translations.find((t) => t.lang === language)?.assr || question.assr;
  const rsn = question.translations.find((t) => t.lang === language)?.rsn || question.rsn;
  const solution = question.translations.find((t) => t.lang === language)?.sol || question.sol;
  const answer = question.translations.find((t) => t.lang === language)?.ar_ans || question.ar_ans;

  if (submitted === undefined || selected === undefined) {
    return <Loading3D />;
  }

  const options = [
    'Both A and B are true and B is the correct explanation of A',
    'Both A and B are true but B is not the correct explanation of A',
    'A is true but B is false',
    'A is false but B is true',
    'Both A and B are false',
  ];

  const chooseOption = (index: number) => {
    if (submitted) {
      return;
    }

    Device.sendPractiseEvent({
      type: 'toggle-answer',
      questionId,
      toggleOption: index + 1,
      questionType: question.type,
      correctAnswer: [question.ar_ans],
      timestamp: getTimeStamp(),
    });
    setSelected(index + 1);
  };

  const isAnswerCorrect = (chosenIndex: number | null | undefined) => {
    if (chosenIndex === null || chosenIndex === undefined) {
      return false;
    }
    return chosenIndex === answer;
  };

  const correct = isAnswerCorrect(selected);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 border p-3 rounded bg-yellow">
            <h2>Assertion Reason</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-3" dangerouslySetInnerHTML={{ __html: assr }}></div>
          <div className="col-12 p-3" dangerouslySetInnerHTML={{ __html: rsn }}></div>
        </div>
        {options.map((option: any, index: number) => (
          <div className="row m-2">
            <div
              key={index}
              className={
                'col-12 border py-2 rounded border-2' +
                (submitted &&
                  (isAnswerCorrect(index + 1) ? ' border-success' : ' ') +
                    (selected === index + 1 && !correct ? ' border-danger' : ' ')) +
                (!submitted && selected === index + 1 ? ' border-primary' : ' ')
              }
              onClick={(e) => {
                chooseOption(index);
              }}
              dangerouslySetInnerHTML={{ __html: option }}
            ></div>
          </div>
        ))}
        <div className="row">
          <div className="col-12 p-3">
            <button
              className="btn btn-primary"
              disabled={!selected || submitted}
              onClick={() => {
                invariant(selected !== null, 'selected should not be null');
                invariant(selected !== undefined, 'selected should not be undefined');
                Device.sendPractiseEvent({
                  type: 'submit-answer',
                  questionId,
                  selectedOptions: [selected],
                  questionType: question.type,
                  correctAnswer: [question.ar_ans],
                  timestamp: getTimeStamp(),
                });
                setSubmitted(true);
              }}
            >
              Submit
            </button>
            {submitted && allowReattempt && (
              <button
                className="btn btn-primary"
                disabled={!submitted}
                onClick={() => {
                  invariant(selected !== null, 'selected should not be null');
                  invariant(selected !== undefined, 'selected should not be undefined');
                  invariant(submitted, 'submitted should be true');
                  Device.sendPractiseEvent({
                    type: 'retry-question',
                    questionId,
                    timestamp: getTimeStamp(),
                  });
                  setSelected(null);
                  setSubmitted(false);
                }}
              >
                Retry
              </button>
            )}
          </div>
        </div>
      </div>
      {submitted && mode === 'immediate' && <SubmitStatus correct={correct} />}
      {submitted && mode === 'immediate' && <TaggedHTML html={solution} tag="Solution" />}
    </>
  );
}
