/*
 * --------------------------------------------------------------------------- *
 * File: device.ts                                                             *
 * Project: content-render                                                     *
 * Created Date: 17 Mar 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Sat Mar 25 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import { practiseEvent } from './components/questions/types';

export class Device {
  static sendPractiseEvent(event: practiseEvent) {
    // @ts-ignore
    if (AppChannel) {
      // @ts-ignore
      AppChannel.postMessage(JSON.stringify({ event }));
    } else {
      console.log('Sending event: ' + JSON.stringify(event));
    }
  }

  static sendMessage(message: string) {
    // @ts-ignore
    if (AppChannel) {
      // @ts-ignore
      AppChannel.postMessage(JSON.stringify({ message }));
    } else {
      console.log('Sending message: ' + JSON.stringify(message));
    }
  }

  static questionUpdate(id: string, update: any) {
    // @ts-ignore
    if (AppChannel) {
      // @ts-ignore
      AppChannel.postMessage(JSON.stringify({ question: id, update }));
    } else {
      console.log('Sending question update: ' + JSON.stringify({ question: id, update }));
    }
  }

  static contentUpdate(id: string, update: any) {
    // @ts-ignore
    if (AppChannel) {
      // @ts-ignore
      AppChannel.postMessage(JSON.stringify({ content: id, update }));
    } else {
      console.log('Sending content update: ' + JSON.stringify({ content: id, update }));
    }
  }
}
