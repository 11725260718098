import { MCQTranslatedQuestion } from './schema';
import { useEffect, useState } from 'react';
import useKatex from '../../useKatex';
import { LanguageCode, FeedbackMode } from './types';
import { TaggedHTML } from './tagged-html';
import { SubmitStatus } from './submit-status';
import { Device } from '../../device';
import invariant from 'tiny-invariant';
import { Loading3D } from '../loading';

export default function MCQQuestion({
  question,
  mode,
  language,
  allowReattempt,
  getTimeStamp,
  questionId,
  selected,
  submitted,
  setSelected,
  setSubmitted,
}: {
  question: MCQTranslatedQuestion;
  mode: FeedbackMode;
  language: LanguageCode;
  allowReattempt: boolean;
  getTimeStamp: () => number;
  questionId: string;
  selected?: number[];
  submitted?: boolean;
  setSelected: (selected: number[]) => void;
  setSubmitted: (submitted: boolean) => void;
}) {
  useKatex();
  const stmt = question.translations.find((t) => t.lang === language)?.stmt || question.stmt;
  const options = question.translations.find((t) => t.lang === language)?.options || question.options;
  const solution = question.translations.find((t) => t.lang === language)?.sol || question.sol;
  const answer = question.translations.find((t) => t.lang === language)?.obj_ans || question.obj_ans;

  if (submitted === undefined || selected === undefined) {
    return <Loading3D />;
  }

  const chooseOption = (index: number) => {
    if (submitted) {
      return;
    }

    Device.sendPractiseEvent({
      type: 'toggle-answer',
      questionId,
      toggleOption: index + 1,
      questionType: question.type,
      correctAnswer: question.obj_ans,
      timestamp: getTimeStamp(),
    });

    if (selected.includes(index + 1)) {
      setSelected([...selected.filter((i) => i !== index + 1)]);
    } else {
      setSelected([...selected, index + 1]);
    }
  };

  const isAnswerCorrect = (chosenIndex: number) => {
    return answer.includes(chosenIndex);
  };

  const correct = selected.every((i) => isAnswerCorrect(i));

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 border p-3 rounded bg-yellow">
            <h2>Multiple Choice</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-3" dangerouslySetInnerHTML={{ __html: stmt }}></div>
        </div>
        {options.map((option: any, index: number) => (
          <div className="row m-2">
            <div
              key={index}
              className={
                'col-12 border py-2 rounded border-2' +
                (submitted &&
                  (isAnswerCorrect(index + 1) ? ' border-success' : ' ') +
                    (selected.includes(index + 1) && !correct ? ' border-danger' : ' ')) +
                (!submitted && selected.includes(index + 1) ? ' border-primary' : ' ')
              }
              onClick={(e) => {
                chooseOption(index);
              }}
              dangerouslySetInnerHTML={{ __html: option }}
            ></div>
          </div>
        ))}
        <div className="row">
          <div className="col-12 p-3">
            <button
              className="btn btn-primary"
              disabled={selected.length > 0 || submitted}
              onClick={() => {
                invariant(selected.length, 'No option selected');
                Device.sendPractiseEvent({
                  type: 'submit-answer',
                  questionId,
                  selectedOptions: selected.map((i) => i + 1),
                  questionType: question.type,
                  correctAnswer: question.obj_ans,
                  timestamp: getTimeStamp(),
                });
                setSubmitted(true);
              }}
            >
              Submit
            </button>
            {submitted && allowReattempt && (
              <button
                className="btn btn-primary"
                disabled={!submitted}
                onClick={() => {
                  invariant(selected !== null, 'selected should not be null');
                  invariant(selected !== undefined, 'selected should not be undefined');
                  invariant(submitted, 'submitted should be true');
                  Device.sendPractiseEvent({
                    type: 'retry-question',
                    questionId,
                    timestamp: getTimeStamp(),
                  });
                  setSelected([]);
                  setSubmitted(false);
                }}
              >
                Retry
              </button>
            )}
          </div>
        </div>
      </div>
      {submitted && mode === 'immediate' && <SubmitStatus correct={correct} />}
      {submitted && mode === 'immediate' && <TaggedHTML html={solution} tag="Solution" />}
    </>
  );
}
